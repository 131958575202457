<template>

  <div>

    <div class="generateIzjavaProstovoljcaOuterContainer">

      <div id="my-node3" class="" v-if="volunteersData">

        <div class="generateOpremaProstovoljcaContainer">
          <img :src="require('@/assets/images/content/business_letters/oprema_prostovoljca.png')" class="bgImg">
          <div class="name">{{ volunteersData.first_name + ' ' + volunteersData.last_name }}</div>
          <div class="address">{{volunteersData.addresses.quick_search}}</div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'IzjavaProstvoljca',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteersData: null

    }
  },

  computed: {

  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log("this.$route.query", this.$route.query);

    if (_this.$route.params.volunteerId) {
      if (this.$route.query.jwt) {
        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );

      }


      _this.getVolunteerData();


    } else {
      _this.$vs.loading.close();
    }
  },


  methods: {
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.$route.params.volunteerId)
        .then((res) => {
          _this.volunteersData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    pad(num, size) {
      let s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}
@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateIzjavaProstovoljcaOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .generateOpremaProstovoljcaContainer {
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 18% 20% 15% 7% !important;
    display: block !important;

    overflow: hidden !important;

    font-size: 10px;
    text-align: justify;

    page-break-inside: avoid;
    position: relative;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: 0;
      left: 0px;
      top: 0px;
    }

    .name {
      text-transform: uppercase;
      font-size: 18px;
      position: absolute;
      left: 306px;
      top: 203px;
      font-weight: bold;
    }


    .address {
      font-size: 12px;
      position: absolute;
      left: 165px;
      top: 249px;
      max-width: 385px;
      font-weight: bold;
    }

  }

}
</style>
